import Fullscreen from "./Fullscreen";
export default class SearchieClient {
  teamHash = null;
  frames = [];
  constructor(teamHash, userData) {
    this.teamHash = teamHash;
    setInterval(() => {
      let frames = this._fetchAllFrames();
      frames.forEach((frame, index) => {
        this._sanitizeFrame(frame);
        frame.contentWindow.postMessage({
          sdkParent: window.location.href,
          sdkIndex: index
        }, process.env.MIX_APP_URL);
      });
    }, 1000);
    if (teamHash && userData) {
      this._attemptAPILogin(userData);
    }
    Fullscreen.setup();
    window.addEventListener("message", ($event) => {
      if (typeof $event.data === 'object') {
        let type = $event.data.type;
        if (type === 'enterFullScreen' || type === 'exitFullScreen') {
          let frame = this.frames[$event.data.frameIndex];
          if ( type === 'enterFullScreen' ) {
            Fullscreen.enter(frame);
          } else {
            Fullscreen.exit(frame);
          }
        }
      }
    }, false);
    window.searchieEvents = {
      fullscreenEnter: frame => {
        Fullscreen.enter(frame);
      },
      fullscreenExit: frame => {
        Fullscreen.exit(frame);
      }
    };
  }

  _attemptAPILogin(userData) {
    if (userData.email || userData.tracking_id) {
      const iframe = document.createElement('iframe');
      iframe.id = 'searchie-audience-auth';
      iframe.onload = () => {
        if (process.env.NODE_ENV !== 'production')
          console.log('Audience auth complete!');
        if (process.env.NODE_ENV === 'production') {
          document.documentElement.removeChild(iframe);
        }
      };
      if (process.env.NODE_ENV === 'production')
        iframe.setAttribute('style', 'display:none');
      this._addUserDataToFrame(userData, iframe, `${process.env.MIX_APP_URL}/auth/audience/${this.teamHash}`);
      document.documentElement.appendChild(iframe);
    }
  }

  _fetchAllFrames() {
    const frames = document.querySelectorAll(`iframe[src^='${process.env.MIX_APP_URL}']:not(#searchie-audience-auth)`);
    this.frames = Array.from(frames);
    // if (process.env.NODE_ENV !== 'production')
    //   console.log('Found Searchie frames:', frames);
    return this.frames || [];
  }

  _addUserDataToFrame(userData, frame, baseUrl = null) {
    const url = new URL(baseUrl || frame.src);
    for (let [key, value] of Object.entries(userData)) {
      url.searchParams.set(key, value);
    }
    frame.src = url.toString();
    // if (process.env.NODE_ENV !== 'production')
    //   console.log(frame.src);
  }

  _sanitizeFrame(frame) {
    frame.removeAttribute('sandbox');
    frame.removeAttribute('security');
    frame.setAttribute('frameborder', 0);
    frame.setAttribute('allow', "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
    frame.setAttribute('allowfullscreen', true);
    // if (process.env.NODE_ENV !== 'production')
    //   console.log('Frame sanitized!', frame);
  }
}
