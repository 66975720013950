import browser from 'plyr/src/js/utils/browser';
import is from 'plyr/src/js/utils/is';
import { toggleClass } from 'plyr/src/js/utils/elements'

export default class Fullscreen {
  static scrollPosition = { x: 0, y: 0 };
  static toggle(toggle = false, el) {
    if (toggle) {
      this.scrollPosition = {
        x: window.scrollX || 0,
        y: window.scrollY || 0,
      };
    } else {
      window.scrollTo(this.scrollPosition.x, this.scrollPosition.y);
    }

    // Toggle scroll
    document.body.style.overflow = toggle ? 'hidden' : '';

    toggleClass(el, "searchie--fullscreen-fallback", toggle);

    let viewport = document.head.querySelector('meta[name="viewport"]');
    const property = 'viewport-fit=cover';

    // Inject the viewport meta if required
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.setAttribute('name', 'viewport');
    }

    // Check if the property already exists
    const hasProperty = is.string(viewport.content) && viewport.content.includes(property);

    if (toggle) {
      this.cleanupViewport = !hasProperty;

      if (!hasProperty) {
        viewport.content += `,${property}`;
      }
    } else if (this.cleanupViewport) {
      viewport.content = viewport.content
        .split(',')
        .filter(part => part.trim() !== property)
        .join(',');
    }
  }
  static enter(frame) {
    if (!browser.isIos) return;
    Fullscreen.toggle(true, frame);
  }
  static exit(frame) {
    if (!browser.isIos) return;
    Fullscreen.toggle(false, frame);
  }
  static setup() {
    if (!browser.isIos) return;
    let style = document.createElement('style');
    style.appendChild(document.createTextNode(`
      .searchie--fullscreen-fallback {
        background: #000!important;
        border-radius: 0!important;
        height: 100%!important;
        margin: 0!important;
        width: 100%!important;
        bottom: 0!important;
        left: 0!important;
        position: fixed!important;
        right: 0!important;
        top: 0!important;
        z-index: 10000000!important;
      }
    `));
    document.head.appendChild(style);
  }
}
